import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const merchGroupModelFragment: DocumentNode = gql`
  fragment merchGroupModelFragment on MerchGroupModel {
    id
    title
    description
    metaDescription
    heroImageUrl
    icon
    slug
  }
`;

export const merchGroupCategoryFragment: DocumentNode = gql`
  fragment merchGroupCategoryFragment on MerchGroupCategory {
    id
    title
    parentId
    headline
    description
    slug
    children: childMerchGroups {
      id
      title
    }
  }
`;
