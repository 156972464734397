import { IMerchGroup } from '@models/MerchGroup/i-merch-group';
import { ApolloClient } from '@apollo/client';
import MERCH_GROUP_CATEGORY_BY_SLUG_QUERY
  from '@components/shared/shop/graphql/merch-group-category-by-slug.graphql';

export const MERCH_GROUP_CATEGORY_URL_PREFIX = '/shop/category';

export interface IMerchGroupCategory {
  id: string;
  title: string;
  slug: string;
  headline?: string;
  description?: string;
  parentId?: number;
  children?: IMerchGroup[];
  selected: boolean;
}

export async function getMerchGroupCategoryBySlug(
  client: ApolloClient<any>,
  slug: string
): Promise<IMerchGroupCategory | undefined> {
  const response = await client.query({
    query: MERCH_GROUP_CATEGORY_BY_SLUG_QUERY,
    fetchPolicy: 'no-cache',
    variables: { slug }
  });

  return response?.data?.getMerchGroupCategoryBySlug;
}
