import React from 'react';
import { IUser } from '@models/User/i-user';
import { IAuthResponse } from '@models/shared/i-auth-response';
import { IExperimentVariation } from '@models/Experiment';

export type { IUser } from '@models/User/i-user';

export interface IUserContext {
  addSavedAssets: (id: number) => void;
  addSavedForLaterVendorVariants: (id: number) => void;
  addSavedVendorVariants: (id: number) => void;
  addCartedVendorVariants: (id: number, quantity?: number) => void;
  login: () => Promise<IAuthResponse | null | void> | IAuthResponse | null | void;
  logout: () => void;
  getCartItemCount: () => number;
  user: IUser | null;
  setUser: (user: IAuthResponse) => void;
  isAdmin: boolean;
  isDesigner: boolean;
  isCustomer: boolean;
  isLoading: boolean;
  experimentVariations: IExperimentVariation[];
  addExperimentVariations: (experimentVariation: IExperimentVariation) => void;
  setExperimentVariations: (experimentVariations: IExperimentVariation[]) => void;
  havenlyAnonymousId?: string;
  removeSavedForLaterVendorVariants: (id: number) => void;
  savedAssetIds: {
    [key: number]: boolean;
  };
  savedForLaterVendorVariants: {
    [key: number]: boolean;
  };
  savedVendorVariantIds: {
    [key: number]: boolean;
  };
  cartedVendorVariantIds: {
    [key: number]: number;
  };
}

/* istanbul ignore next */
const UserContext = React.createContext<IUserContext>({
  addSavedAssets: () => {},
  addSavedForLaterVendorVariants: () => {},
  addSavedVendorVariants: () => {},
  addCartedVendorVariants: () => {},
  login: () => {},
  logout: () => {},
  getCartItemCount: () => 0,
  user: null,
  setUser: () => {},
  isAdmin: false,
  isDesigner: false,
  isCustomer: false,
  isLoading: true,
  experimentVariations: [],
  addExperimentVariations: () => {},
  setExperimentVariations: () => {},
  havenlyAnonymousId: '',
  removeSavedForLaterVendorVariants: () => {},
  savedAssetIds: {},
  savedForLaterVendorVariants: {},
  savedVendorVariantIds: {},
  cartedVendorVariantIds: {},
});

export default UserContext;
