/* istanbul ignore file */
// ignoring this file because i'm running out of time
// and not sure how to write tests involving an iframe
import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import classNames from 'classnames';

import env from '../../../lib/envs/env';
import UserContext from '../../../contexts/User/user-context';
import MessagingContext from '../../../contexts/User/messaging-context';
import styles from './Messaging.module.scss';

interface IMessagingProps {
  className?: string;
}

export default function Messaging(props: IMessagingProps) {
  const {
    className = '',
  } = props;

  const {
    isOpen,
    roomId,
  } = useContext(MessagingContext);

  const [link, setLink] = useState<string | undefined>();

  const messagingWrapperClassName = classNames(
    styles.MessagingWrapper,
    { [styles.isOpen]: isOpen },
    'not-snackbar',
    className,
  );

  /*
    https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  */
  useEffect(() => {
    if (isOpen) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.width = '100%';

      if (!link) {
        setLink(getLink(roomId));
      }
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }, [isOpen]);

  const { user } = useContext(UserContext);

  return (
    <div
      className={ messagingWrapperClassName }
    >
      {user ? (
        <iframe
          className={ styles.Frame }
          src={ link }
          title="Messaging"
          onLoad={() => {
            // if this is going to take a long time use onLoad to know loading state
          }}
        />
      ) : null}
    </div>
  );
}

function getLink(roomId?: string | number | null): string {
  let link = '/messaging';
  if (env.ENV === 'local') {
    link = 'http://havenly.local/messaging';
  }
  if (roomId) {
    link += `?room_id=${roomId}`;
  }

  return link;
}
