import { EventHint, Scope, Severity } from '@sentry/types';

import logger from '@lib/logger';
import env from '../envs/env';

/**
 * Sentry
 *
 * Even if Sentry is not enabled, we still want to export an instance of it
 * So we don't have to check for availability in the application code.
 * Sentry will not send any data unless the init function has been called.
 */
/* istanbul ignore file */

let Sentry = require('@sentry/browser'); // eslint-disable-line import/no-mutable-exports

if (env.SENTRY_ENABLED) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.APP_ENV ?? 'unknown',
    release: env.SENTRY_RELEASE ?? 'customer-ui@local',
    ignoreErrors: [
      /_AutofillCallbackHandler/, // Seems to be an Instagram browser specific issue
      /\(reading 'TenantFeatures'\)/, // OneTrust script exception
    ]
  });
} else {
  /**
     * If Sentry is not enabled, override its methods to console methods so developers
     * get instant feedback in the console without sending messages off to the server.
     */
  Sentry = {
    ...Sentry,
    captureException: (exception: any, hint?: EventHint, scope?: Scope):
        string | undefined => {
      logger('sentry').error(exception, hint, scope);

      return undefined;
    },
    captureMessage: (message: string, level?: Severity, hint?: EventHint, scope?: Scope):
        string | undefined => {
      logger('sentry').warn(message, level, hint, scope);

      return undefined;
    },
  };
}

export default Sentry;
