import { IVendorVariantInternal } from '@models/VendorVariant/i-vendor-variant';

// plid = product list id
const productListRegex = /#plid=(.*?)(&[a-z]|$)/;

export default function getVendorVariantAnalyticsPayload(
  vendorVariant: Partial<IVendorVariantInternal> | undefined | null
) {
  if (!vendorVariant) {
    return undefined;
  }

  let listId = null;
  if (window && window.location && window.location.hash) {
    const matches = window.location.hash.match(productListRegex);
    listId = matches ? matches[1] : null;
  }
  const vendorVariantId = vendorVariant.id ? Number(vendorVariant.id) : undefined;
  const currentPrice = vendorVariant.currentPrice
    || vendorVariant.price
    || vendorVariant.discountedPrice
    || vendorVariant.retailPrice?.price;
  return {
    availability: vendorVariant.availability?.replace(/_/g, '-'),
    brand: vendorVariant.vendor?.name,
    category: vendorVariant.taxonomy?.title,
    imageUrl: vendorVariant.images ? vendorVariant.images[0]?.uri : undefined,
    listId,
    listPosition: null,
    name: vendorVariant.title,
    price: vendorVariant.retailPrice?.price,
    productId: vendorVariantId?.toString(),
    productName: vendorVariant.title,
    salePrice: vendorVariant.price || vendorVariant.discountedPrice || undefined,
    sku: vendorVariant.sku,
    taxonomy: vendorVariant.taxonomy?.title,
    vendorName: vendorVariant.vendor?.name,
    vendorVariantId,
    ecommProdid: vendorVariantId,
    ecommPagetype: 'product',
    ecommTotalvalue: currentPrice,
    ecommCategory: vendorVariant.taxonomy?.title,
    dynxItemid: vendorVariantId
  };
}

export function getVendorVariantIterablePayload(
  vendorVariant: Partial<IVendorVariantInternal> | undefined
) {
  if (!vendorVariant) {
    return undefined;
  }

  const vendorVariantId = vendorVariant.id ? Number(vendorVariant.id) : undefined;
  return {
    brand: vendorVariant.vendor?.name,
    category: vendorVariant.taxonomy?.title,
    imageUrl: vendorVariant.images ? vendorVariant.images[0]?.uri : undefined,
    name: vendorVariant.title,
    price: vendorVariant.retailPrice?.price,
    productId: vendorVariantId?.toString(),
    sku: vendorVariant.sku
  };
}
