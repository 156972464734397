import jsonwebtoken from 'jsonwebtoken';

/**
 * Determines if the given JWT has expired.
 * @param jwt The JWT to decode and check.
 */
// eslint-disable-next-line import/prefer-default-export
export function isExpired(jwt: string) {
  const decoded = jsonwebtoken.decode(jwt) as any;
  if (decoded?.exp) {
    const nowSeconds = Math.round(new Date().getTime() / 1000);
    return (nowSeconds > decoded.exp);
  }

  return false;
}
