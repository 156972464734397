import { ApolloClient } from '@apollo/client';
import { IMerchGroup } from '@models/MerchGroup/i-merch-group';
import MERCH_GROUPS_QUERY from '../graphql/merch-groups.graphql';
import MERCH_GROUP_BY_SLUG_QUERY from '../graphql/merch-group-by-slug.graphql';

export const MERCH_GROUP_URL_PREFIX = '/shop/collection';

export async function getMerchGroupBySlug(
  client: ApolloClient<any>,
  slug: string
): Promise<IMerchGroup | undefined> {
  const response = await client.query({
    query: MERCH_GROUP_BY_SLUG_QUERY,
    variables: { slug }
  });

  return response?.data?.merchGroupBySlug;
}

export async function getMerchGroups(
  client: ApolloClient<any>,
  slugs?: string[]
): Promise<IMerchGroup[] | undefined> {
  const response = await client.query({ query: MERCH_GROUPS_QUERY });

  return response?.data?.merchGroups.map((group: any) => ({
    ...group,
    selected: !!slugs && slugs.length > 0 && group.slug === slugs[0]
  }));
}
