import gql from 'graphql-tag';
import { merchGroupCategoryFragment } from './fragments.graphql';

export default gql`
  ${merchGroupCategoryFragment}
  query MerchGroupCategoryBySlug($slug: String!) {
    getMerchGroupCategoryBySlug(slug: $slug) {
      __typename
      ...merchGroupCategoryFragment
    }
  }
`;

