import reverseObject from '@lib/reverseObject';
import slugify from '@lib/slugify';

export const CONTEMPORARY = 'CONTEMPORARY';
export const MODERN = 'MODERN';
export const CLASSIC = 'CLASSIC';
export const ECLECTIC = 'ECLECTIC';
export const BOHEMIAN = 'BOHEMIAN';
export const COASTAL = 'COASTAL';
export const GLAM = 'GLAM';
export const INDUSTRIAL = 'INDUSTRIAL';
export const TRADITIONAL = 'TRADITIONAL';
export const FARMHOUSE = 'FARMHOUSE';
export const RUSTIC = 'RUSTIC';
export const TRANSITIONAL = 'TRANSITIONAL';
export const LIBRARY = 'LIBRARY';
export const VINTAGE = 'VINTAGE';
export const GLOBAL = 'GLOBAL';
export const COUNTRY = 'COUNTRY';
export const SOUTHWEST_INSPIRED = 'SOUTHWEST_INSPIRED';
export const MIDCENTURY_MODERN = 'MIDCENTURY_MODERN';
export const MINIMAL = 'MINIMAL';
export const CLASSIC_CONTEMPORARY = 'CLASSIC_CONTEMPORARY';
export const PREPPY = 'PREPPY';
export const SCANDINAVIAN = 'SCANDINAVIAN';

export const subStylesById: { [key: number]: string } = {
  1: CONTEMPORARY,
  2: MODERN,
  3: CLASSIC,
  4: ECLECTIC,
  5: BOHEMIAN,
  6: COASTAL,
  7: GLAM,
  8: INDUSTRIAL,
  9: TRADITIONAL,
  10: FARMHOUSE,
  11: RUSTIC,
  12: TRANSITIONAL,
  13: LIBRARY,
  14: VINTAGE,
  15: GLOBAL,
  16: COUNTRY,
  17: SOUTHWEST_INSPIRED,
  18: MIDCENTURY_MODERN,
  19: MINIMAL,
  20: CLASSIC_CONTEMPORARY,
  21: PREPPY,
  22: SCANDINAVIAN,
};

export const subStylesDisplayNameById: { [key: number]: string } = {
  1: 'Contemporary',
  2: 'Modern',
  3: 'Classic',
  4: 'Eclectic',
  5: 'Bohemian',
  6: 'Coastal',
  7: 'Glam',
  8: 'Industrial',
  9: 'Traditional',
  10: 'Farmhouse',
  11: 'Rustic',
  12: 'Transitional',
  13: 'Library',
  14: 'Vintage',
  15: 'Global',
  16: 'Country',
  17: 'Southwest Inspired',
  18: 'Midcentury Modern',
  19: 'Minimal',
  20: 'Classic Contemporary',
  21: 'Preppy',
  22: 'Scandinavian',
};

export const subStylesHrefByName: { [key: string]: string } = {
  Contemporary: '/exp/contemporary-design-ideas',
  Modern: '/exp/modern-design-ideas',
  Classic: '/exp/classic-design-ideas',
  Eclectic: '/exp/eclectic-design-ideas',
  Bohemian: '/exp/bohemian-design-ideas',
  Coastal: '/exp/coastal-design-ideas',
  Glam: '/exp/glam-design-ideas',
  Industrial: '/exp/industrial-design-ideas',
  Traditional: '/exp/traditional-design-ideas',
  Farmhouse: '/exp/interior-design-ideas',
  Rustic: '/exp/rustic-design-ideas',
  Transitional: '/exp/transitional-design-ideas',
  Library: '/exp/interior-design-ideas',
  Vintage: '/exp/interior-design-ideas',
  Global: '/exp/interior-design-ideas',
  Country: '/exp/interior-design-ideas',
  'Southwest Inspired': '/exp/interior-design-ideas',
  'Midcentury Modern': '/exp/midcentury-modern-design-ideas',
  Minimal: '/exp/interior-design-ideas',
  'Classic Contemporary': '/exp/classic-design-ideas',
  Preppy: '/exp/preppy-design-ideas',
  Scandinavian: '/exp/scandinavian-design-ideas',
};

export const subStylesByName = reverseObject(subStylesById, true);
export const subStylesDisplayById = reverseObject(subStylesDisplayNameById, true);

export const SubStyleSlugsById = Object.entries(subStylesById)
  .reduce((acc, next) => {
    const _acc = { ...acc };
    const [id, name] = next;
    _acc[parseInt(id, 10)] = slugify(name);
    return _acc;
  }, {} as { [key: number]: string });

export const SubStyleSlugsByName = reverseObject(SubStyleSlugsById, true);
