import { IExperimentVariation } from '@models/Experiment';
import { IIdentifyExperiments } from './analyticsIdentify';

/**
 * Creates a copy of the provided object, converting the keys to `snake_case` in the process.
 * This function also converts objects within this object.
 * @param object The object to convert.
 */
export function convertObjectKeys(object: {[key: string]: any}) {
  const ret: {[key: string]: any} = {};

  Object.keys(object).forEach((key) => {
    const newKey = key.split(/(?=[A-Z])/).join('_').toLowerCase();
    let value = object[key];

    // Test if its an object (arrays are considered objects, so perform a second check too).
    if (value instanceof Object && !Array.isArray(value)) {
      value = convertObjectKeys(value);
    }

    if (Array.isArray(value)) {
      value = value.map((v) => {
        if (v instanceof Object && !Array.isArray(v)) {
          return convertObjectKeys(v);
        }
        return v;
      });
    }

    ret[newKey] = value;
  });

  return ret;
}

export function convertProps(properties?: {[key: string]: any}) {
  let convertedProps = properties;

  // Reshape the payload given to suit the analytics software.
  if (convertedProps) {
    convertedProps = convertObjectKeys(convertedProps);
  }

  return convertedProps;
}

export function reduceExperiments(experimentVariations: IExperimentVariation[]):
IIdentifyExperiments {
  return experimentVariations?.reduce((obj: {[key:string]: string}, variation) => {
    // eslint-disable-next-line no-param-reassign
    obj[variation.experiment.title] = variation.title;
    return obj;
  }, {}) ?? {};
}
