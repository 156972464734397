/* istanbul ignore file */

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
// http://unixpapa.com/js/key.html

export default {
  // Special keys
  13: 'Enter',
  32: 'Space',
  27: 'Escape',

  // Numbers
  48: '0',
  41: '0',
  49: '1',
  33: '1',
  50: '2',
  64: '2',
  51: '3',
  35: '3',
  52: '4',
  36: '4',
  53: '5',
  37: '5',
  54: '6',
  94: '6',
  55: '7',
  38: '7',
  56: '8',
  42: '8',
  57: '9',
  40: '9',

  // Symbols
  44: ',',
  60: ',',
  188: ',',

  46: '.',
  62: '.',
  190: '.',

  47: '/',
  63: '/',
  191: '/',

  58: ';',
  59: ';',
  186: ';',

  96: '`',
  126: '`',
  192: '`',

  39: '\'',
  34: '\'',
  222: '\'',

  91: '[',
  123: '[',
  219: '[',

  93: ']',
  125: ']',
  221: ']',

  92: '\\',
  124: '\\',
  220: '\\',

  45: '-',
  95: '-',
  173: '-',
  189: '-',

  61: '=',
  43: '=',
  187: '=',
};
