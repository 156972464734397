/* istanbul ignore file */

export default function reverseObject(object: any, useIntegers?: boolean) {
  const newObject: any = {};
  Object.keys(object).forEach((key) => {
    newObject[object[key]] = useIntegers ? parseInt(key, 10) : key;
  });
  return newObject;
}

