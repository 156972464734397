import gql from 'graphql-tag';

const getDiscoverFeedQuery = (queryVariables: any) => {
  const variables = queryVariables;

  const query = gql`
    query DiscoverFeed(
      $pageNumber: Int!,
      $pageSizeBoards: Int!,
      $roomTypeIds: [ID!],
      $subStyleIds: [ID!],
      $sortOrder: String!,
    ) {
    discoverFeedBoards(
    pageNumber: $pageNumber,
    pageSize: $pageSizeBoards,
    roomTypeIds: $roomTypeIds,
    subStyleIds: $subStyleIds,
    sortOrder: $sortOrder,
    ) {
    itemCount
    pageNumber
    pageSize
    items {
    id
    __typename
    mainBoardAsset {
    id
    asset {
    id
    url
    assetTypeId
    }
    }
    boardProductCount
    designer {
    id
    firstName
    vanityId
    profileUri
    }
    room {
    id
    roomType {
    id
    type
    }
    }
    subStyles {
    id
    name
    }
    }
    }
    }
  `;

  return [query, variables];
};

export const SEARCH_DISCOVER_PAGES = gql`
  query SearchDiscoverPages($query: String!) {
    searchDiscoverPages(query: $query) {
      slug
      name
    }
  }
`;

export default getDiscoverFeedQuery;
