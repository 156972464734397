import gql from 'graphql-tag';

export default gql`
  query DiscoverPage(
    $pageNumber: Int!,
    $pageSizeBoards: Int!,
    $slug: String!,
  ) {

  discoverPage: discoverPageBySlug(
    slug: $slug,
  ) {
    description
    header
    id
    metaDescription
    name
    slug
    title
    blogPosts {
      postId,
      title,
      mediumImage,
      largeImage,
      url
    }
    __typename
    boards(
      pageNumber: $pageNumber,
      pageSize: $pageSizeBoards,
    ) {
      itemCount
      pageNumber
      pageSize
      items {
        id
        __typename
        mainBoardAsset {
          id
          asset {
            id
            url
            assetTypeId
          }
        }
        boardProductCount
        designer {
          id
          firstName
          vanityId
          profileUri
        }
        room {
          id
          roomType {
            id
            type
          }
        }
        subStyles {
          id
          name
        }
        publicName
        roomType {
          id
          type
        }
      }
    }
  }
}`;
