import { SubStyleSlugsById, SubStyleSlugsByName } from '@constants/subStyles';
import { orderBy } from 'lodash';
import { RoomTypeSlugsById, RoomTypeSlugsByName } from './model/RoomTypeSlugs';

const FILTER_PARAM_SEPARATOR = '-_-';

export const ROOM_TYPE_PARAM = 'roomType';
export const SUB_STYLE_PARAM = 'style';

function paramToArrayValue(param: string = '', defaultValue: string[] = []): string[] {
  if (!param) {
    return defaultValue;
  }
  const arr = param.split(FILTER_PARAM_SEPARATOR).filter((val) => !!val.trim());
  return arr || [];
}

function arrayValueToParam(value: string[] = []): string | null {
  // Remove from query params if falsy.
  // Except 0, which is treated as a valid value.
  if (!Array.isArray(value) || value.length === 0) {
    return null;
  }

  return orderBy(
    value.filter((v) => !!v.trim()),
    [(v) => v.toLowerCase()],
    ['asc'],
  ).join(FILTER_PARAM_SEPARATOR);
}

export function getRoomTypeFilter(roomTypeParam: string): number[] {
  const roomTypeSlugs = paramToArrayValue(roomTypeParam, []);
  return roomTypeSlugs
    .map((slug) => RoomTypeSlugsByName[slug])
    .filter((id) => id !== undefined);
}

export function getRoomTypeFilterParam(roomTypes: number[]): string | null {
  return arrayValueToParam(roomTypes.map((id) => RoomTypeSlugsById[id]));
}

export function getSubStyleFilter(subStyleParam: string): number[] {
  const subStyleSlugs = paramToArrayValue(subStyleParam, []);
  return subStyleSlugs
    .map((slug) => SubStyleSlugsByName[slug])
    .filter((id) => id !== undefined);
}

export function getSubStyleFilterParam(subStyles: number[]): string | null {
  return arrayValueToParam(subStyles.map((id) => SubStyleSlugsById[id]));
}
