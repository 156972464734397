import { v4 as uuidv4 } from 'uuid';
import { IncomingMessage, ServerResponse } from 'http';
import ServerCookieService from '../ServerCookieService';
import ICookieService from '../ICookieService';

export const HAVENLY_ANONYMOUS_ID_COOKIE = 'havenly_anonymous_id';

export default function setHavenlyAnonymousId(req: IncomingMessage, res: ServerResponse) {
  const cookieService = new ServerCookieService(req, res);
  setHavenlyAnonymousIdCookie(cookieService);
}

export function setHavenlyAnonymousIdCookie(cookieService: ICookieService) {
  const now = new Date();
  // expires 3 months after now, notice that 'now' variable is also mutated
  const expires = new Date(now.setMonth(now.getMonth() + 3));

  let havenlyAnonymousId = cookieService.getCookie(HAVENLY_ANONYMOUS_ID_COOKIE);
  if (!havenlyAnonymousId) {
    havenlyAnonymousId = uuidv4();
  }
  cookieService.setCookie({
    name: HAVENLY_ANONYMOUS_ID_COOKIE,
    value: havenlyAnonymousId,
    expiration: expires,
    httpOnly: false,
    sameSite: 'strict'
  });

  return havenlyAnonymousId;
}
