import { getApollo } from '@lib/apollo/apolloClient';
import { gql } from '@apollo/client';

const MEGAMENU_SHOP_QUERY = gql`
  query Prismic {
    prismicMegamenuShop
  }
`;

/**
 * need to fetch this data on every page but also want to cache the result
 * so we're not going over on our prismic bill.
 * this would be easy if we had upgraded nextjs to 13 and could use the new next
 * fetch polyfill caching options, but we haven't yet so we're calling
 * to our graph backend and caching the result in redis
 */
export default async function fetchMegamenuShop() {
  const apolloClient = getApollo({
    cacheEnabled: true
  });

  const res = await apolloClient.query({
    query: MEGAMENU_SHOP_QUERY
  });

  const megamenuShop = res.data?.prismicMegamenuShop
    ? JSON.parse(res.data.prismicMegamenuShop)
    : null;

  return megamenuShop;
}
