import { IMegaMenuColumn } from '@components/shared/Navigation/components/MainHeader/Desktop/MegaMenu/MegaMenu';
import React, { Dispatch, SetStateAction } from 'react';

export interface IShopNavigationContext {
  shopNavigationData: IMegaMenuColumn[];
  isShopNavigationHovered: boolean;
  setShopNavigationHovered: Dispatch<SetStateAction<boolean>>;
}

const ShopNavigationContext = React.createContext<IShopNavigationContext>({
  shopNavigationData: [],
  isShopNavigationHovered: false,
  setShopNavigationHovered: () => null,
});

export default ShopNavigationContext;
