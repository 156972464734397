import { useEffect, useState } from 'react';

function useLocalStorage<T>(key: string, initialValue: any) {
  const [initialized, setInitialized] = useState(false);
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        setStoredValue(JSON.parse(item));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setInitialized(true);
    }
  }, []);

  const setValue = (value: T | ((currentValue: T) => T)) => {
    return setStoredValue((currentValue: T) => {
      const valueToStore = value instanceof Function ? value(currentValue) : value;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      return valueToStore;
    });
  };

  const removeValue = () => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return {
    value: storedValue,
    setValue,
    removeValue,
    initialized
  };
}

export default useLocalStorage;
