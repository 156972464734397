import gql from 'graphql-tag';
import { merchGroupModelFragment } from '@components/shared/shop/graphql/fragments.graphql';

export default gql`
  ${merchGroupModelFragment}
  query ShopMerchGroupBySlug($slug: String!) {
    merchGroupBySlug(slug: $slug) {
      __typename
      ...merchGroupModelFragment
    }
  }
`;

