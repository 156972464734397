import React from 'react';

import useLocalStorage from '@hooks/UseLocalStorage/UseLocalStorage';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import getVendorVariantAnalyticsPayload, {
  getVendorVariantIterablePayload
} from '@lib/vendorVariant/getVendorVariantAnalyticsPayload';
import { IVendorVariantInternal } from '@models/VendorVariant/i-vendor-variant';

const LOCAL_CART = 'local_cart';

export interface ILocalCartItem {
  vendorVariant: Partial<IVendorVariantInternal>;
  quantity: number;
}

interface IHandleLocalAddToCart {
  vendorVariant: Partial<IVendorVariantInternal>;
  quantity: number;
  location: string;
  listId?: string;
}

interface ILocalCartContext {
  initialized: boolean;
  localCart: ILocalCartItem[];
  localSavedForLaterProducts: ILocalCartItem[];
  totalCartItems: number;
  handleLocalAddToCart: ({
    vendorVariant,
    quantity,
    location,
    listId
  }: IHandleLocalAddToCart) => void;
  convertFromCartProductToUserSaved: (vendorVariantId: string) => void;
  convertFromUserSavedToCartProduct: (vendorVariantId: string) => void;
  cleanLocalCart: () => void;
  checkIfItemExists: (vendorVariantId: string) => boolean;
  removeLocalCartItem: (vendorVariantId: string) => void;
  removeLocalSavedForLaterProductsItem: (vendorVariantId: string) => void;
  updateLocalCartItem: (vendorVariantId: string, quantity: number) => void;
}

const LocalCartContext = React.createContext<ILocalCartContext>({} as ILocalCartContext);

interface ILocalCartProvider {
  children: any;
}

interface IHandleSetValueInput {
  cartItems?: ILocalCartItem[];
  localSavedForLaterProducts?: ILocalCartItem[];
}

function LocalCartProvider({ children }: ILocalCartProvider) {
  const { value, setValue, initialized } = useLocalStorage<{
    localCart: ILocalCartItem[];
    localSavedForLaterProducts: ILocalCartItem[];
    totalCartItems: number;
  }>(LOCAL_CART, {});

  const handleSetValue = ({
    cartItems,
    localSavedForLaterProducts
  }: IHandleSetValueInput) => {
    if (cartItems !== undefined && localSavedForLaterProducts !== undefined) {
      setValue({
        localCart: cartItems,
        localSavedForLaterProducts,
        totalCartItems:
          cartItems.length > 0
            ? cartItems.reduce((prev: number, curr: any) => prev + curr.quantity, 0)
            : 0
      });

      return;
    }

    if (cartItems !== undefined) {
      setValue({
        localCart: cartItems,
        localSavedForLaterProducts: value.localSavedForLaterProducts,
        totalCartItems:
          cartItems.length > 0
            ? cartItems.reduce((prev: number, curr: any) => prev + curr.quantity, 0)
            : 0
      });
    }

    if (localSavedForLaterProducts !== undefined) {
      setValue({
        localCart: value.localCart,
        localSavedForLaterProducts,
        totalCartItems: value.totalCartItems
      });
    }
  };

  const handleLocalAddToCart = ({
    vendorVariant,
    quantity,
    location,
    listId
  }: IHandleLocalAddToCart) => {
    const localCart = value.localCart ? [...value.localCart] : [];

    const vendorVariantId = vendorVariant.id ? Number(vendorVariant.id) : undefined;

    const newCartItem = {
      vendorVariant,
      quantity
    };

    // @ts-ignore
    const analyticsPayload = getVendorVariantAnalyticsPayload(vendorVariant);
    const payload = {
      cartId: null,
      cartQuantity: (value && value.totalCartItems) || 0,
      cartQuantityChange: quantity,
      location,
      quantity,
      products:
        value &&
        value.localCart &&
        value.localCart.map((cp) => ({
          quantity: cp.quantity,
          // @ts-ignore
          ...getVendorVariantIterablePayload(cp.vendorVariant)
        })),
      listId: undefined,
      ...analyticsPayload
    };

    if (listId) {
      payload.listId = listId;
    }

    analyticsTrack('Product Added', payload);

    if (!localCart || localCart.length === 0) {
      handleSetValue({ cartItems: [newCartItem] });
      return;
    }

    // check if cartItem exists in the current local storage
    const cartItemIndex = localCart.findIndex(
      (c) => c.vendorVariant?.id === vendorVariantId?.toString()
    );

    if (cartItemIndex !== -1) {
      const newLocalCart = localCart.map((item) => {
        if (item.vendorVariant?.id === vendorVariantId?.toString()) {
          return { ...item, quantity: item.quantity + quantity };
        }

        return item;
      });

      handleSetValue({ cartItems: newLocalCart });
    } else {
      handleSetValue({ cartItems: [...localCart, newCartItem] });
    }
  };

  const convertFromCartProductToUserSaved = (vendorVariantId: string) => {
    const localCart = value.localCart ? [...value.localCart] : [];

    const newLocalCart = localCart.filter(
      (item) => item.vendorVariant?.id?.toString() !== vendorVariantId
    );

    const cartProductToConvert = localCart.find(
      (item) => item.vendorVariant?.id?.toString() === vendorVariantId
    );

    if (cartProductToConvert) {
      handleSetValue({
        cartItems: newLocalCart,
        localSavedForLaterProducts: [
          ...(value.localSavedForLaterProducts || []),
          cartProductToConvert
        ]
      });
    }
  };

  const convertFromUserSavedToCartProduct = (vendorVariantId: string) => {
    const localSavedForLaterProducts = value.localSavedForLaterProducts
      ? [...value.localSavedForLaterProducts]
      : [];

    const newSavedProducts = localSavedForLaterProducts.filter(
      (item) => item.vendorVariant?.id?.toString() !== vendorVariantId
    );

    const savedProductToConvert = localSavedForLaterProducts.find(
      (item) => item.vendorVariant?.id?.toString() === vendorVariantId
    );

    if (savedProductToConvert) {
      handleSetValue({
        cartItems: [...value.localCart, savedProductToConvert],
        localSavedForLaterProducts: newSavedProducts
      });
    }
  };

  const cleanLocalCart = () => {
    handleSetValue({ cartItems: [], localSavedForLaterProducts: [] });
  };

  const checkIfItemExists = (vendorVariantId: string) => {
    // make sure localCart is not empty
    if (value.localCart && value.localCart.length > 0) {
      return value.localCart.some(
        (item) => item.vendorVariant?.id?.toString() === vendorVariantId
      );
    }
    return false;
  };

  const removeLocalCartItem = (vendorVariantId: string) => {
    const localCart = value.localCart ? [...value.localCart] : [];
    const cartItemIndex = localCart.findIndex(
      (c) => c.vendorVariant?.id?.toString() === vendorVariantId
    );

    if (cartItemIndex !== -1) {
      localCart.splice(cartItemIndex, 1);
      handleSetValue({ cartItems: localCart });
    }
  };

  const removeLocalSavedForLaterProductsItem = (vendorVariantId: string) => {
    const localSavedForLaterProducts = value.localSavedForLaterProducts
      ? [...value.localSavedForLaterProducts]
      : [];

    const localSavedForLaterProductIndex = localSavedForLaterProducts.findIndex(
      (item) => item.vendorVariant?.id?.toString() === vendorVariantId
    );

    if (localSavedForLaterProductIndex !== -1) {
      localSavedForLaterProducts.splice(localSavedForLaterProductIndex, 1);
      handleSetValue({ localSavedForLaterProducts });
    }
  };

  const updateLocalCartItem = (vendorVariantId: string, quantity: number) => {
    const localCart = value.localCart ? [...value.localCart] : [];
    const cartItemIndex = localCart.findIndex(
      (c) => c.vendorVariant?.id?.toString() === vendorVariantId
    );

    if (cartItemIndex !== -1) {
      localCart[cartItemIndex].quantity = quantity;
      handleSetValue({ cartItems: localCart });
    }
  };

  return (
    <LocalCartContext.Provider
      value={{
        initialized,
        localCart: value.localCart,
        localSavedForLaterProducts: value.localSavedForLaterProducts || [],
        handleLocalAddToCart,
        convertFromCartProductToUserSaved,
        convertFromUserSavedToCartProduct,
        totalCartItems: value.totalCartItems,
        cleanLocalCart,
        checkIfItemExists,
        removeLocalCartItem,
        updateLocalCartItem,
        removeLocalSavedForLaterProductsItem
      }}
    >
      {children}
    </LocalCartContext.Provider>
  );
}

export { LocalCartProvider, LocalCartContext };
