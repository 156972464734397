/* istanbul ignore file */

import Cookie from 'js-cookie';
import gql from 'graphql-tag';
import { IExperimentVariation } from '@models/Experiment';
import { JWT_COOKIE } from '@lib/auth/auth';
import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import { getApollo } from '@lib/apollo/apolloClient';
import { TEST_EXPERIMENT_COOKIE } from '@lib/experiment';

interface IUserCookies {
  jwt: string;
  havenlyAnonymousId?: string;
  experimentVariationIds?: string[];
}

// pass jwt and havenlyAnonymousId as params if loading experiments server side
export default async function fetchUserExperimentVariations(
  userCookies?: IUserCookies,

): Promise<IExperimentVariation[]> {
  const jwt = userCookies?.jwt || Cookie.get(JWT_COOKIE);
  const havenlyAnonymousId = userCookies?.havenlyAnonymousId
    || Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE);
  const experimentVariationIds = userCookies?.experimentVariationIds
    || Cookie.get(TEST_EXPERIMENT_COOKIE)?.split(',');

  const apolloClient = getApollo({ jwt });
  const response = await apolloClient.query({
    query: gql`
        query MyExperimentVariations(
          $havenlyAnonymousId: String!
          $testVariationIds: [ID!]
        ) {
          myExperimentVariations(
            havenlyAnonymousId: $havenlyAnonymousId
            testVariationIds: $testVariationIds
          ) {
            id
            title
            experiment {
              id
              title
            }
          }
        }
    `,
    variables: {
      havenlyAnonymousId: havenlyAnonymousId || '',
      testVariationIds: experimentVariationIds || []
    },
  });

  const { data: { myExperimentVariations: experimentVariations } } = response;

  return experimentVariations || [];
}
