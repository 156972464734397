/* istanbul ignore file */
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { getApollo } from '@lib/apollo/apolloClient';
import useAlert, { TYPES } from '@hooks/useAlert/useAlert';
import DiscoverFeedContext, { IDiscoverFeedContext } from '@context/DiscoverFeed/discover-feed-context';
import { getRoomTypeFilter, getSubStyleFilter } from '@components/pages/ExploreFeed/exploreFilters';
import getDiscoverFeedQuery from '@components/pages/ExploreFeed/graphql/discoverFeed.graphql';
import DISCOVER_PAGE_QUERY from '@components/pages/ExploreFeed/graphql/discoverPage.graphql';

// ensures a stable reference to default boards/products
// prevents unessesary re-renders by DiscoverFeedProvider
const DEFAULT_INITIAL_BOARDS: any[] = [];
const DEFAULT_BLOG_POSTS: any[] = [];
const DEFAULT_QUERY_VARIABLES: any = {
  roomTypeIds: [],
  subStyleIds: [],
};

export default function DiscoverFeedProvider(props: {
  children: any;
  initialBoards?: any[];
  blogPosts?: any[];
  queryVariables?: any;
  isDiscoverPage: boolean;
}) {
  const {
    children,
    initialBoards = DEFAULT_INITIAL_BOARDS,
    blogPosts = DEFAULT_BLOG_POSTS,
    queryVariables = DEFAULT_QUERY_VARIABLES,
    isDiscoverPage,
  } = props;
  const router = useRouter();
  const errorAlert = useAlert(TYPES.ERROR);

  const initialPageNumber = (initialBoards.length) ? 1 : 0;
  const initialRoomTypeIds = getRoomTypeFilter(router.query.roomType as string || '');
  const initialSubStyleIds = getSubStyleFilter(router.query.style as string || '');

  const [loadingResults, setLoadingResults] = useState(false);
  const [boards, setBoards] = useState<any[]>(initialBoards);
  const [posts, setPosts] = useState<any[]>(blogPosts);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [searchRoomType, setSearchRoomType] =
    useState(initialRoomTypeIds as number[]);
  const [searchStyle, setSearchStyle] =
    useState(initialSubStyleIds as number[]);

  const refresh = async (): Promise<[any, number]> => {
    try {
      setLoadingResults(true);
      const newPageNumber = 1;

      const [
        newBoards,
        newPosts
      ] = await fetchFeed(isDiscoverPage, {
        ...queryVariables,
        roomTypeIds: searchRoomType,
        subStyleIds: searchStyle,
        pageNumber: newPageNumber,
      });

      if (newBoards) {
        setBoards(newBoards);
      }
      if (newPosts) {
        setPosts(newPosts);
      }
      setPageNumber(newPageNumber);

      return [undefined, (newBoards?.length || 0)];
    } catch (e) {
      errorAlert('Failed to load explore items.', 3000);
      return [e, 0];
    } finally {
      setLoadingResults(false);
    }
  };

  const fetchMore = async (): Promise<[any, number]> => {
    try {
      setLoadingResults(true);
      const nextPage = pageNumber + 1;

      const [
        additionalBoards,
      ] = await fetchFeed(isDiscoverPage, {
        ...queryVariables,
        roomTypeIds: searchRoomType,
        subStyleIds: searchStyle,
        pageNumber: nextPage,
      });

      if (additionalBoards) {
        setBoards([...boards, ...additionalBoards]);
      }
      setPageNumber(nextPage);

      return [undefined, (additionalBoards?.length || 0)];
    } catch (e) {
      errorAlert('Failed to load explore items.', 3000);
      return [e, 0];
    } finally {
      setLoadingResults(false);
    }
  };

  return (
    <DiscoverFeedContext.Provider
      value={{
        boards,
        posts,
        searchRoomType,
        searchStyle,
        loadingResults,
        setSearchRoomType,
        setSearchStyle,
        fetchMore,
        refresh,
      } as IDiscoverFeedContext}
    >
      {children}
    </DiscoverFeedContext.Provider>
  );
}

async function fetchFeed(
  isDiscoverPage: boolean,
  queryVariables: any,
): Promise<[any, any]> {
  const apollo = getApollo();

  if (isDiscoverPage) {
    const { data: { discoverPage } } =
      await apollo.query({
        query: DISCOVER_PAGE_QUERY,
        variables: queryVariables,
      });

    const newBoards = discoverPage?.boards?.items || [];
    const newPosts = discoverPage?.blogPosts || [];
    return [newBoards, newPosts];
  }

  const [query, variables] = getDiscoverFeedQuery({ ...queryVariables });

  const {
    data: {
      discoverFeedBoards,
    },
  } = await apollo.query({ query, variables });

  const newBoards = discoverFeedBoards?.items || [];

  return [newBoards, undefined];
}
