/* eslint-disable class-methods-use-this */
// TODO: Fix ESLint issues
// @ts-nocheck
import React from 'react';
import App, { AppProps, AppContext } from 'next/app';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import Cookie from 'js-cookie';
import SnackbarProvider from 'react-simple-snackbar';
import DatadogBrowserLogsInit from '@lib/datadog/DatadogBrowserLogsInit';
import logger, { Logger } from '@lib/logger';
import { APOLLO_STATE_PROP_NAME, useApollo } from '@lib/apollo/apolloClient';
import '../styles/styles.scss';
import 'swiper/swiper-bundle.css';
import {
  getJwtFromApigilityToken,
  login,
  logout,
  needsJwtApigilityRefresh,
  refreshToken,
  verifyCurrentUser,
} from '@lib/auth/auth';
import Compose from '@components/shared/Compose';
import DiscoverFeedProvider from '@components/pages/ExploreFeed/components/DiscoverFeedProvider';
import { IUser } from '@models/User/i-user';
import { IAuthResponse } from '@models/shared/i-auth-response';
import ThemeProvider from '@components/theme/ThemeProvider';
import UserContext, { IUserContext } from '@context/User/user-context';
import StripeProvider from '@context/Stripe/StripeProvider';
import initSegment from '@lib/analytics/init';
import { MessagingProvider } from '@components/shared/Messaging';
import keyboardShortcuts from '@lib/keyboardShortcuts';
import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import { ExperimentTitles, IExperimentVariation } from '@models/Experiment';
import { fetchUserExperimentVariations } from '@lib/user';
import analyticsIdentify from '@lib/analytics/analyticsIdentify';
import Sentry from '@lib/sentry/sentry';
import promotionalCodeCookie from '@lib/cookie/promotionalCode';
import { LocalCartProvider } from '@context/LocalCart/LocalCartContext';

import Script from 'next/script';

import env from '@lib/envs/env';
import { assignUserToAnonymousExperiment, userInExperiment } from '@lib/experiment';
import ShopNavigationProvider, { IPrismicShopNavData } from '@components/shared/shop/ShopNavigationMenu/ShopNavigationProvider';
import fetchMegamenuShop from '@lib/prismic/fetchMegamenuShop';
import { defaultShopNav } from '@components/shared/Navigation/components/config/megaMenuShop.config';

interface IMyAppState {
  userState: IUserContext;
  refreshTimeoutId: number;
  shopNavigationData?: IPrismicShopNavData;
}

/* istanbul ignore file */
export default class MyApp extends App<{}, AppProps, IMyAppState> {
  private log: Logger;

  constructor(props: any, context: AppContext) {
    super(props, context);
    initSegment();
    this.log = logger('_app');
    const { user: initialUser } = props.pageProps;
    this.state = {
      userState: {
        addCartedVendorVariants: () => {},
        addSavedAssets: () => {},
        addSavedForLaterVendorVariants: () => {},
        addSavedVendorVariants: () => {},
        login: () => {},
        logout: () => {},
        getCartItemCount: () => 0,
        user: initialUser,
        get isAdmin() {
          return this.user?.role === 'admin';
        },
        get isDesigner() {
          return this.user?.role === 'designer';
        },
        get isCustomer() {
          return this.user?.role === 'customer';
        },
        setUser: () => { },
        isLoading: true,
        experimentVariations: this.props.pageProps.experimentVariations,
        addExperimentVariations: () => { },
        setExperimentVariations: () => { },
        havenlyAnonymousId: '',
        removeSavedForLaterVendorVariants: () => { },
        savedAssetIds: {},
        savedForLaterVendorVariants: this.props.pageProps.initialSavedForLaterVendorVariants || {},
        savedVendorVariantIds: {},
        cartedVendorVariantIds: this.props.pageProps.initialCartVendorVariants || {},
      } as IUserContext,
      refreshTimeoutId: 0,
      shopNavigationData: undefined
    };
  }

  /*
    Adding this so we can update experimentVariations in the page file
    Without this change, those updates don't propagate to FE UserContext
    while using Next router navigation (i.e. without a fresh page load)
  */
  static getDerivedStateFromProps(props: any, state: any) {
    if (props?.pageProps?.experimentVariations) {
      return {
        ...state,
        userState: {
          ...state.userState,
          experimentVariations: props.pageProps.experimentVariations
        }
      };
    }

    return state;
  }

  async componentDidMount() {
    keyboardShortcuts.init();
    // must call this before calling fetchUserExperimentVariations
    // or anything that needs access to cookies
    await fetch(`/api/cookies`, {})
      .catch((error) => this.log.error(error));

    if (needsJwtApigilityRefresh()) {
      await getJwtFromApigilityToken()
        .catch((error) => this.log.error(error));
    }

    const { user: initialUser, experimentVariations } = this.state.userState;
    if (initialUser) {
      this.setTrackingInfo(initialUser, experimentVariations);
    }

    const verifiedUser = await verifyCurrentUser(this.state.userState.user);

    this.setState({
      userState: {
        addCartedVendorVariants: (id: number, quantity = 1) => this.setState({
          userState: {
            ...this.state.userState,
            cartedVendorVariantIds: {
              ...this.state.userState.cartedVendorVariantIds,
              [id]: quantity,
            },
          },
        }),
        addSavedAssets: (id: number) => this.setState({
          userState: {
            ...this.state.userState,
            savedAssetIds: {
              ...this.state.userState.savedAssetIds,
              [id]: true,
            },
          },
        }),
        addSavedForLaterVendorVariants: (id: number) => this.setState({
          userState: {
            ...this.state.userState,
            savedForLaterVendorVariants: {
              ...this.state.userState.savedForLaterVendorVariants,
              [id]: true,
            },
          },
        }),
        addSavedVendorVariants: (id: number) => this.setState({
          userState: {
            ...this.state.userState,
            savedVendorVariantIds: {
              ...this.state.userState.savedVendorVariantIds,
              [id]: true,
            },
          },
        }),
        login: this.login.bind(this),
        logout: this.logout.bind(this),
        getCartItemCount: this.getCartItemCount.bind(this),
        user: verifiedUser,
        get isAdmin() {
          return this.user?.role === 'admin';
        },
        get isDesigner() {
          return this.user?.role === 'designer';
        },
        get isCustomer() {
          return this.user?.role === 'customer';
        },
        experimentVariations: this.state.userState.experimentVariations
          || await fetchUserExperimentVariations(),
        setUser: this.setUser.bind(this),
        addExperimentVariations: this.addExperimentVariations.bind(this),
        setExperimentVariations: this.setExperimentVariations.bind(this),
        isLoading: false,
        havenlyAnonymousId: Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE),
        removeSavedForLaterVendorVariants: (id: number) => this.setState({
          userState: {
            ...this.state.userState,
            savedForLaterVendorVariants: {
              ...this.state.userState.savedForLaterVendorVariants,
              [id]: false,
            },
          },
        }),
        savedAssetIds: this.state.userState.savedAssetIds,
        savedForLaterVendorVariants: this.state.userState.savedForLaterVendorVariants,
        savedVendorVariantIds: this.state.userState.savedVendorVariantIds,
        cartedVendorVariantIds: this.state.userState.cartedVendorVariantIds,
      },
    });

    // Assign to Mini Removal experiment, regardless of the entry page
    await this.assignToMiniRemovalExperiment(experimentVariations);

    // Same for Pricing Cards experiment
    await this.assignToPricingCardsExperiment(experimentVariations);

    // Fetch shop navigation nav highlight section data from Prismic
    await this.fetchShopNavigationData();

    if (!this.state.refreshTimeoutId) {
      /*
        Initial timeout is 30 minutes while subsequent timeouts will be 1 hour,
        this will offset hourly requests to land in the middle of the hour
      */
      this.setTokenRefreshTimeout(1800);

      /*
        Also refresh token when user returns focus to tab in case they were
        tabbed away during a refresh timeout.
      */
      document.addEventListener('visibilitychange', this.refreshAuthToken);
    }
  }

  componentDidUpdate(_: any, prevState: any) {
    // identify user if updated
    // eg when logging in somehow or user context changes
    const { user, experimentVariations } = this.state.userState;
    const { user: prevUser } = prevState.userState;
    if (user && user !== prevUser) {
      this.setTrackingInfo(user, experimentVariations);
    }
  }

  componentWillUnmount() {
    keyboardShortcuts.destroy();
    document.removeEventListener('visibilitychange', this.refreshAuthToken);
    if (this.state.refreshTimeoutId) {
      window.clearTimeout(this.state.refreshTimeoutId);
    }
  }

  setTokenRefreshTimeout = (timeoutSeconds: number = 3600) => {
    const timeout = timeoutSeconds * 1000;

    const refreshTimeoutId = window.setTimeout(async () => {
      await this.refreshAuthToken();
      this.setTokenRefreshTimeout(timeoutSeconds * 2);
    }, timeout);

    this.setState({ refreshTimeoutId });
  };

  refreshAuthToken = async () => {
    if (!document.hidden && this.state.userState.user) {
      await refreshToken();
    }
  };

  async login(): Promise<IAuthResponse | null> {
    this.setState((prevState) => ({
      ...prevState,
      userState: {
        ...prevState.userState,
        isLoading: true
      }
    }));
    const user = await login();

    this.setState((prevState) => {
      return {
        ...prevState,
        userState: {
          ...prevState.userState,
          user,
          isLoading: false
        }
      };
    });

    return user;
  }

  logout() {
    this.setState((prevState) => {
      const newState: any = { ...prevState };
      newState.userState.user = null;
      logout();

      return newState;
    });
  }

  setUser(user: IUser) {
    return this.setState((prevState) => {
      const newState: any = { ...prevState };
      newState.userState.user = {
        ...newState.userState.user,
        ...user,
      };

      return newState;
    });
  }

  setExperimentVariations(experimentVariations: IExperimentVariation[]) {
    return this.setState((prevState) => {
      return {
        ...prevState,
        userState: {
          // @ts-ignore
          ...prevState.userState,
          experimentVariations,
        },
      };
    });
  }

  addExperimentVariations(experimentVariation: IExperimentVariation) {
    return this.setState((prevState) => {
      return {
        ...prevState,
        userState: {
          // @ts-ignore
          ...prevState.userState,
          experimentVariations: [
            // @ts-ignore
            ...prevState.userState.experimentVariations,
            experimentVariation,
          ],
        },
      };
    });
  }

  getCartItemCount() {
    return Object.values(
      this.state.userState.cartedVendorVariantIds
    ).reduce((acc, cur) => acc + cur, 0);
  }

  handlePromotionalCode(router: NextRouter) {
    if (typeof window !== 'undefined') {
      const promotionCode = router.query.promotionalCode as string;
      if (promotionCode) {
        localStorage.setItem('promotionCode', promotionCode); // handle old page
        promotionalCodeCookie.set(promotionCode);
      }
    }
  }

  setTrackingInfo(user: any, experimentVariations: IExperimentVariation[]) {
    const { id, email } = user;
    analyticsIdentify(user, experimentVariations);
    Sentry.setUser({ id, email });
  }

  async assignToMiniRemovalExperiment(experimentVariations: IExperimentVariation[]) {
    const miniRemovalTitle = ExperimentTitles.MINI_PACKAGE_REMOVAL;
    if (!userInExperiment(miniRemovalTitle, experimentVariations)) {
      const [, allVariations] = await assignUserToAnonymousExperiment(
        Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE),
        miniRemovalTitle,
        experimentVariations,
      );

      this.state.userState.experimentVariations = allVariations;
    }
  }

  async assignToPricingCardsExperiment(experimentVariations: IExperimentVariation[]) {
    const title = ExperimentTitles.HOMEPAGE_PRICING_CARDS_2023;
    if (!userInExperiment(title, experimentVariations)) {
      const [, allVariations] = await assignUserToAnonymousExperiment(
        Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE),
        title,
        experimentVariations,
      );

      this.state.userState.experimentVariations = allVariations;
    }
  }

  async fetchShopNavigationData() {
    try {
      const prismicData = await fetchMegamenuShop();
      this.state.shopNavigationData = prismicData?.data || [];
    } catch (e: any) {
      this.state.shopNavigationData = defaultShopNav;
      logger('fetchShopNavigationData').error(e, 'Unable to fetch shop nav data from Prismic');
      Sentry.captureException(e);
    }
  }

  render() {
    const { Component, pageProps, router } = this.props as any;
    const { userState } = this.state;

    this.handlePromotionalCode(router);

    return (
      <>
        <Head>
          <meta
            content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
            name="viewport"
          />
          <title>Online Interior Design and Home Decorating | Havenly</title>
        </Head>
        <Compose
          components={[
            [HavenlyApolloProvider, { pageProps }],
            StripeProvider,
            SnackbarProvider,
            [UserContext.Provider, { value: userState }],
            LocalCartProvider,
            MessagingProvider,
            [DiscoverFeedProvider, {
              initialBoards: pageProps.initialBoards,
              blogPosts: pageProps.blogPosts,
              initialProducts: pageProps.initialProducts,
              queryVariables: pageProps.queryVariables,
              isDiscoverPage: !!pageProps.discoverPage,
            }],
            ThemeProvider,
            [ShopNavigationProvider, {
              prismicNavData: this.state.shopNavigationData
            }]
          ]}
        >
          <Component {...pageProps} />
        </Compose>
        <Script
          strategy="afterInteractive"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-domain-script={getOneTrustId()}
        />
        <Script src="https://static.cdn.prismic.io/prismic.js?new=true&repo=havenly-cms" />
        <DatadogBrowserLogsInit />
      </>
    );
  }
}

function HavenlyApolloProvider({ children, pageProps }: any) {
  let jwt;
  if (pageProps?.user?.jwt && typeof window === 'undefined') {
    jwt = pageProps?.user?.jwt;
  }

  const apolloClient = useApollo({ initialCacheState: pageProps[APOLLO_STATE_PROP_NAME], jwt });
  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
}

function getOneTrustId() {
  return env.ONE_TRUST_USE_TEST_SCRIPT
    ? [env.ONE_TRUST_ID, 'test'].join('-')
    : env.ONE_TRUST_ID;
}
