import { ExperimentTitles, IExperimentVariation } from '@models/Experiment';
import { getApollo, IApolloConfig } from '@lib/apollo/apolloClient';
import gql from 'graphql-tag';
import { IUserContext } from '@context/User/user-context';
import logger from '@lib/logger';
import { uniqBy } from 'lodash';

const log = logger('Experiments');

export const TEST_EXPERIMENT_COOKIE = 'havenly_test_experiment_variations';

const ADD_TO_ANONYMOUS_EXPERIMENT = gql`
  mutation addUserToAnonymousExperiment(
    $anonymousUser: String!,
    $experimentTitle: String!
  ) {
    addUserToAnonymousExperiment(
      anonymousUser: $anonymousUser
      experimentTitle: $experimentTitle
    ) {
      id
      title
      experiment {
        id
        title
      }
    }
  }
`;

export const ADD_USER_TO_EXPERIMENT = gql`
  mutation addUserToExperiment(
      $userId: ID!,
      $experimentTitle: String!
  ) {
      addUserToExperiment(
          userId: $userId
          experimentTitle: $experimentTitle
      ) {
          id
          title
          experiment {
            id
            title
          }
      }
  }
`;

export async function userInOrAssignToExperiment(
  userContext: IUserContext,
  experimentTitle: string
): Promise<IExperimentVariation | undefined> {
  if (!userContext || !experimentTitle || !userContext.experimentVariations) {
    return undefined;
  }

  const { addExperimentVariations, user, experimentVariations } = userContext;

  const variation = userVariationForExperiment(experimentTitle, experimentVariations);
  if (variation) return variation;

  const userId = user?.id;
  const experimentVariation = await assignUserToExperiment(userId, experimentTitle);

  if (experimentVariation) {
    addExperimentVariations(experimentVariation);
  }

  return experimentVariation;
}

export async function assignUserToExperiment(
  userId?: string,
  experimentTitle?: string,
  apolloClientConfig?: IApolloConfig,
): Promise<IExperimentVariation | undefined> {
  if (!userId || !experimentTitle) {
    return undefined;
  }

  const apolloClient = getApollo(apolloClientConfig);
  const response = await apolloClient.mutate<{ addUserToExperiment: IExperimentVariation}>({
    mutation: ADD_USER_TO_EXPERIMENT,
    variables: {
      userId,
      experimentTitle,
    },
  });

  return response.data?.addUserToExperiment;
}

async function _assignUserToAnonymousExperiment(
  havenlyAnonymousId: string | undefined | null,
  experimentTitle: string,
): Promise<IExperimentVariation | undefined> {
  if (!havenlyAnonymousId) {
    return undefined;
  }

  const apolloClient = getApollo();
  const response = await apolloClient.mutate({
    mutation: ADD_TO_ANONYMOUS_EXPERIMENT,
    variables: {
      anonymousUser: havenlyAnonymousId,
      experimentTitle,
    },
  });

  const { data: { addUserToAnonymousExperiment: experimentVariation } } = response;

  if (experimentVariation) {
    log.debug({
      havenlyAnonymousId,
      experiment: {
        id: experimentVariation?.experiment?.id,
        title: experimentVariation?.experiment?.title
      },
      variation: {
        id: experimentVariation?.id,
        title: experimentVariation?.title,
      }
    }, 'Added user to experiment variation');
  }

  return experimentVariation || undefined;
}

/**
 * Assigns a user to the given experiment, if not already assigned.
 * @param havenlyAnonymousId
 * @param experimentTitle
 * @param existingVariations
 */
export async function assignUserToAnonymousExperiment(
  havenlyAnonymousId: string | undefined | null,
  experimentTitle: string,
  existingVariations: IExperimentVariation[] = [],
): Promise<[IExperimentVariation | undefined, IExperimentVariation[]]> {
  let testVariation = userVariationForExperiment(experimentTitle, existingVariations);

  let allVariations = existingVariations;
  if (!testVariation) {
    testVariation = await _assignUserToAnonymousExperiment(havenlyAnonymousId, experimentTitle);
    if (testVariation) {
      allVariations = [...existingVariations, testVariation];
    }
  }

  return [testVariation, allVariations];
}

export function userInExperiment(
  experimentTitle: string,
  experimentVariations: IExperimentVariation[],
): boolean {
  return !!userVariationForExperiment(experimentTitle, experimentVariations);
}

/**
 * Finds the variation for the given experiment from the list of experiment variations
 * @param experimentTitle
 * @param experimentVariations
 */
export function userVariationForExperiment(
  experimentTitle: string,
  experimentVariations: IExperimentVariation[] = [],
): IExperimentVariation | undefined {
  return experimentVariations.find((variation) => variation.experiment.title === experimentTitle);
}

/**
 * Determines if a user is in an experiment variation based on the given experiment
 * variations they belong to
 * @param variationTitle
 * @param experimentVariations
 */
export function userInExperimentVariation(
  variationTitle: string,
  experimentVariations: IExperimentVariation[],
): boolean {
  return !!userExperimentVariation(variationTitle, experimentVariations);
}

/**
 * Returns the experiment variation from the list of variations that matches the given title
 * @param variationTitle
 * @param experimentVariations
 */
export function userExperimentVariation(
  variationTitle: string,
  experimentVariations: IExperimentVariation[],
): IExperimentVariation | undefined {
  return experimentVariations &&
      experimentVariations.find(({ title }) => title === variationTitle);
}

/**
 * adds user to anonymous experiment if active and returns experiment variation
 * if experiment is inactive no variation will be returned
 * @param experimentTitle
 * @param userContext
 */
export async function assignToAnonymousExperiment(
  experimentTitle: ExperimentTitles,
  userContext: IUserContext,
  anonymousId?: string
): Promise<IExperimentVariation | null> {
  const {
    experimentVariations,
    addExperimentVariations,
  } = userContext;

  const havenlyAnonymousId = anonymousId ?? userContext.havenlyAnonymousId;

  if (userVariationForExperiment(experimentTitle, experimentVariations)) {
    return userVariationForExperiment(experimentTitle, experimentVariations)!;
  }

  const [assignedVariation] =
    await assignUserToAnonymousExperiment(
      havenlyAnonymousId,
      experimentTitle,
      experimentVariations,
    );

  if (assignedVariation) {
    addExperimentVariations(assignedVariation);
  }

  return assignedVariation || null;
}

export async function assignUserIfNotForcedByCookie(
  id: string | undefined,
  title: string,
  apolloConfig: IApolloConfig,
  experimentVariations: IExperimentVariation[]
) {
  const variation = await assignUserToExperiment(id, title, apolloConfig);

  if (
    variation
      // we might be forcing participation in a variation with a cookie for e2es
      // in that case discard assignment
      && !experimentVariations.find((ev) => ev.experiment.id === variation.experiment.id)
  ) {
      experimentVariations = uniqBy([...experimentVariations, variation], 'id'); // eslint-disable-line
  }

  return experimentVariations;
}
