/* istanbul ignore file */
// Mostly copied from:
// https://github.com/vercel/next.js/tree/canary/examples/with-apollo

import { useMemo } from 'react';
import { ApolloClient, createHttpLink } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';

import fetch from 'isomorphic-unfetch';

import logger from '@lib/logger';
import { GraphQLError } from 'graphql';
import promiseToObservable from '@lib/apollo/promiseToObservable';
import Sentry from '@lib/sentry/sentry';
import { isExpired } from '@utils/jwt';
import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import Cookie from 'js-cookie';
import { Scope } from '@sentry/types';
import env from '../envs/env';
import { getJwt, getJwtFromApigilityToken } from '../auth/auth';
import isBrowser from '../../utils/browser/isBrowser';

export { ApolloClient } from '@apollo/client';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_CACHE_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const log = logger('ApolloClient');

export interface IApolloConfig {
  cacheEnabled?: boolean;
  initialCacheState?: NormalizedCacheObject;
  jwt?: string;
}

const httpLink = createHttpLink({
  uri: env.getApolloUrl(),
  credentials: 'same-origin',
  fetch,
  includeExtensions: true,
});

const addIdentificationHeaders = (headers: any, jwt?: string) => {
  const token = jwt || getJwt();
  const anonymousId = Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Anonymous-Id': anonymousId
    },
  };
};

const authLink = (jwt?: string) => {
  return setContext((_, { headers }) => {
    const token = jwt || getJwt();
    if (token && isExpired(token)) {
      log.debug(`Token is expired, re-authenticating`);
      return getJwtFromApigilityToken()
        .then(() => addIdentificationHeaders(headers));
    }

    return addIdentificationHeaders(headers, token);
  });
};

function isAuthError(err: GraphQLError) {
  return ['UNAUTHENTICATED', 'FORBIDDEN'].includes(err.extensions?.code);
}

/**
 * if a graph request results in an auth error, refreshes jwt from apigility token and retries 1x
 * https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
 */
const retryAuthLink = onError(({ graphQLErrors, operation, forward }: ErrorResponse) => {
  if (graphQLErrors?.find(isAuthError)) {
    return promiseToObservable(getJwtFromApigilityToken())
      .flatMap(() => {
        const oldHeaders = operation.getContext().headers;
        operation.setContext(addIdentificationHeaders(oldHeaders));

        return forward(operation);
      });
  }

  // returning nothing prevents a retry
  return undefined;
});

const onBrowserError = ({ operation, graphQLErrors, networkError }: ErrorResponse) => {
  // Blatantly stolen from https://dev.to/namoscato/graphql-observability-with-sentry-34i6
  Sentry.withScope((scope: Scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext('apolloGraphQLOperation', {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    });

    graphQLErrors?.forEach((error) => {
      Sentry.captureMessage(error.message, {
        level: Sentry.Severity.Error,
        fingerprint: ['{{ default }}', '{{ transaction }}'],
        contexts: {
          apolloGraphQLError: {
            error,
            message: error.message,
            extensions: error.extensions,
          },
        },
      });
    });

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: Sentry.Severity.Error,
        contexts: {
          apolloNetworkError: {
            error: networkError,
            extensions: (networkError as any).extensions,
          },
        },
      });
    }
  });
};

const onServerError = ({ graphQLErrors, networkError }: ErrorResponse) => {
  if (process.env.NODE_ENV !== 'production') {
    // Don't leak low-level errors to the browser in production
    let errors = '';
    if (graphQLErrors) {
      errors += graphQLErrors.map(({ message, locations, path }) => {
        return `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`;
      }).join('\n');
    }

    if (networkError) {
      errors = `${errors}\n[Network error]: ${networkError}`;
    }

    if (errors) {
      log.warn(`Error executing GraphQL request:\n${errors}`);
    }
  }
};

const logErrorLink = onError((errorResponse: ErrorResponse) => {
  if (isBrowser()) {
    onBrowserError(errorResponse);
  } else {
    onServerError(errorResponse);
  }
});

function createApolloClient(clientConfig?: IApolloConfig) {
  const cacheEnabled =
    typeof clientConfig?.cacheEnabled === 'undefined' ? isBrowser() : clientConfig.cacheEnabled;

  const client = new ApolloClient({
    name: 'customer-ui',
    version: process.env.NEXT_BUILD_ID || 'local',
    ssrMode: !isBrowser(),
    link: logErrorLink
      .concat(authLink(clientConfig?.jwt))
      .concat(retryAuthLink)
      .concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: cacheEnabled ? undefined : {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (clientConfig?.initialCacheState) {
    client.cache.restore(clientConfig.initialCacheState);
  }

  return client;
}

/**
 * @alias initializeApollo
 */
export const getApollo = initializeApollo;

export function initializeApollo(clientConfig?: IApolloConfig): ApolloClient<any> {
  const _apolloClient = apolloClient || createApolloClient(clientConfig); // eslint-disable-line

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<any>,
  pageProps: { props: any | undefined }
) {
  if (pageProps?.props) {
    return {
      ...pageProps,
      props: {
        ...pageProps.props,
        [APOLLO_STATE_PROP_NAME]: client.cache.extract()
      }
    };
  }

  return pageProps;
}

export function useApollo(clientConfig: IApolloConfig) {
  return useMemo(() => initializeApollo(clientConfig), [clientConfig?.initialCacheState]);
}
