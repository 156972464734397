/* istanbul ignore file */

import specialCharacterCodes from './specialCharacterCodes';
import specialCharacters from './specialCharacters';
import logger from '../logger';

class KeyboardShortcuts {
  disabledElementTypes = [
    'input',
    'textarea',
  ];

  /*
  Handlers is a map of shortcuts to handler lists. A handler list is an array of configuration
  objects, each with a 'handler' property.

  handlers = {
    'Escape'           : [ <options>,  <options>,  ... ],
    'Cmd-Shift-Ctrl-g' : [ <options>,  <options>,  ... ],
  }

  options = {
    shortcut    : String,
    description : String,
    handler     : Function,
  }
  */
  handlers = {};

  os = null;

  supportedPlatforms = [
    'Mac',
    'Windows',
  ];

  init() {
    if (window.KeyboardShortcuts !== undefined) return;

    // Only handle keyboard shortcuts for supported platforms
    this._getOSPlatform();
    if (!this.supportedPlatforms.includes(this.os)) return;

    // This is set to make sure this class is a singleton
    window.KeyboardShortcuts = this;

    document.addEventListener('keydown', this._handleDocumentKeypress);
  }

  destroy() {
    document.removeEventListener('keydown', this._handleDocumentKeypress);
  }

  registerKeyboardShortcut = (...args) => args.forEach((options) => {
    this._validateOptions(options);

    if (!Array.isArray(this.handlers[options.shortcut])) this.handlers[options.shortcut] = [];

    this.handlers[options.shortcut].push(options);
  });

  unregisterKeyboardShortcut = (...args) => args.forEach((options) => {
    if (typeof options.shortcut !== 'string' || !options.shortcut) return;
    if (typeof options.handler !== 'function') return;
    if (!Array.isArray(this.handlers[options.shortcut])) return;

    this.handlers[options.shortcut] = this.handlers[options.shortcut]
      .filter(({ handler }) => handler !== options.handler);
  });

  _handleDocumentKeypress = (event) => {
    const normalizedShortcut = this._translateKeyCodesIntoShortcut(event);

    // We don't mess with key events inside text fields, except to unfocus them
    if (this.disabledElementTypes.includes(event.target.nodeName.toLowerCase())) {
      if (normalizedShortcut === 'Escape') event.target.blur();
      return;
    }

    this._log(`KeyboardShortcuts detected key combination: ${normalizedShortcut}`);

    if (!Array.isArray(this.handlers[normalizedShortcut])) return;

    // TODO: allow stopImmediatePropagation() or return false?
    this.handlers[normalizedShortcut].forEach(({ handler }) => handler(event));
  };

  /*
  Canonical form for shortcuts:
  <key-modifier-list>-<key-identifier>

  key-modifier-list:
  [Cmd][-Ctrl][-Alt][-Shift]

  key-identifier:
  [a-z0-9,./'[]\`-=]|Esc|Space

  Note: Only lowercase characters are allowed for key identifiers
  */
  _translateKeyCodesIntoShortcut = (event) => {
    const keys = [];

    if (event.metaKey) keys.push('Cmd');
    if (event.ctrlKey) keys.push('Ctrl');
    if (event.altKey) keys.push('Alt');
    if (event.shiftKey) keys.push('Shift');

    keys.push(this._getKeyCharacter(event));

    return keys.join('-');
  };

  _getKeyCharacter(event) {
    if (event.key) {
      if (event.key.length !== 1) return event.key;
      if (/^[a-zA-Z]$/.test(event.key)) return event.key.toLowerCase();
      if (/^[0-9]$/.test(event.key)) return event.key;

      if (Object.keys(specialCharacters).includes(event.key)) {
        return specialCharacters[event.key];
      }
    }

    const keyCode = event.keyCode || event.which || event.charCode;
    if (!keyCode) return null;

    // Letter codes
    if (keyCode >= 97 && keyCode <= 122) return String.fromCharCode(keyCode);
    if (keyCode >= 65 && keyCode <= 90) return String.fromCharCode(keyCode).toLowerCase();

    // Special characters
    if (Object.keys(specialCharacterCodes).includes(`${keyCode}`)) {
      return specialCharacterCodes[keyCode];
    }

    this._displayError(`KeyboardShortcuts: could not find key for keyCode: ${keyCode}`);

    return null;
  }

  // TODO: Fancier validation
  _validateOptions(options) {
    if (typeof options.shortcut !== 'string' || !options.shortcut) {
      return this._displayError(
        `Invalid shortcut given, expected a non-empty string and got: ${options.shortcut}`,
      );
    }

    if (typeof options.description !== 'string' || !options.description) {
      return this._displayError(
        `Invalid description given, expected a non-empty string and got: ${options.shortcut}`,
      );
    }

    if (typeof options.handler !== 'function') {
      return this._displayError(
        `Invalid handler given, expected a function and got: ${options.shortcut}`,
      );
    }

    return true;
  }

  _displayError = (error) => {
    const log = logger('keyboardShortcuts');
    if (process.env.NODE_ENV === 'production') {
      log.warn(`Keyboard Shortcuts: ${error}`);
    }

    if (process.env.NODE_ENV === 'development') {
      log.warn(`Keyboard Shortcuts: ${error}`);
      throw new Error(`Keyboard Shortcuts: ${error}`);
    }

    return false;
  };

  _log = (message) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(message); // eslint-disable-line no-console
    }
  };

  _getOSPlatform() {
    if (window.navigator.platform.indexOf('Mac') !== -1) this.os = 'Mac';
    if (window.navigator.platform.indexOf('Win') !== -1) this.os = 'Windows';
  }
}

const keyboardShortcuts = new KeyboardShortcuts();

export default keyboardShortcuts;
