import gql from 'graphql-tag';

export default gql`
  query Me {
    me {
      id
      role
      firstName
      lastName
      zipCode
      unreadMessageCount
      cart {
        id
      }
      userProfile {
        id
        dateCompleted
      }
      userStyles {
        ranking
        style {
          name
        }
      }
      designerInfo {
        displayName
        timezone
        id
        isHomeStylist
        needsReview
        isStudio3d
        isInPerson
        isOverstockDesigner
        acceptedTerms
        requiresTermsAcceptance
        designerGroups {
          id
          title
        }
      }
      preferredDesigner {
        id
        firstName
        lastName
        photo
      }
      referralCode {
        id
        code
        referralLink
      }
      subStyles {
        id
        name
      }
      mostRecentRoom {
        id
        name: title
        roomPackage {
          id
          type
          description
          designFee
        }
        designer {
          id
          displayName
          photo
        }
      }
      isHVC
    }
  }
`;
