import gql from 'graphql-tag';

export default gql`
  query ShopMerchGroups {
    merchGroups {
      id
      title
      description
      metaDescription
      heroImageUrl
      icon
      slug
      visibilities {
        merchGroupVisibilityTypeId
      }
    }
  }
`;

