import React from 'react';
import ThemeContext, { IThemeContext } from './ThemeContext';
import defaultTheme from './default';

interface IThemeProviderProps {
  children?: any;
  theme?: IThemeContext;
}

const ThemeProvider = ({
  children,
  theme = defaultTheme
}: IThemeProviderProps) => {
  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
