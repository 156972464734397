import Cookie from 'js-cookie';

import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import { IUser } from '@models/User/i-user';
import { IExperimentVariation } from '@models/Experiment';
import env from '../envs/env';
import { reduceExperiments } from './converters';

interface ITopStyles {
  primaryStyleId: string | null;
  secondaryStyleId: string | null;
}

export interface IIdentifyExperiments {
  [experimentTitle: string]: string;
}

interface AnalyticsIdentifyUser {
  createdAt: string | null;
  registeredAt: string | null;
  email: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  timeZone: string | null;
  timezone: string | null ;
  referralCode: string | null;
  surveyStyle: string | null;
  currentRoom: string | null;
  currentDesigner: {
    id: string;
    name: string;
    profile: string | null;
  } | null;
  currentPackage: string | null;
  preferredDesigner: {
    id: string;
    firstName: string;
    lastName: string;
    photo: string;
  } | null;
  designerInfo: {
    id: string;
  } | null;
  experiments: IIdentifyExperiments;
  topStyles: ITopStyles | null;
}

/**
 * Identifies a user
 * Segment recommends that you make an Identify call:
    After a user first registers
    After a user logs in
    When a user updates their info (for example, they change or add a new address)
    Upon loading any pages that are accessible by a logged in user (optional)
 * https://segment.com/docs/connections/spec/identify/
 */
export default function analyticsIdentify(
  user: IUser,
  experimentVariations: IExperimentVariation[]
) {
  if (!env.ANALYTICS_ENABLED || !window || !window.analytics || !window.analytics.track) {
    return;
  }

  const analyticsData: AnalyticsIdentifyUser = {
    createdAt: user.created,
    registeredAt: user.created,
    email: user.email ?? null,
    name: user.username ?? null,
    firstName: user.firstName ?? null,
    lastName: user.lastName ?? null,
    role: user.role,
    timeZone: user.timezone,
    timezone: user.timezone,
    referralCode: user.referralCode?.code ?? null,
    currentRoom: user.mostRecentRoom?.name ?? null,
    currentDesigner: user.mostRecentRoom && user.mostRecentRoom.designer ? {
      id: user.mostRecentRoom.designer.id,
      name: user.mostRecentRoom.designer.displayName,
      profile: user.mostRecentRoom.designer.photo ?? null,
    } : null,
    currentPackage: user.mostRecentRoom?.roomPackage?.type ?? null,
    surveyStyle: user.subStyles?.[0]?.name ?? null,
    preferredDesigner: user.preferredDesigner ?? null,
    designerInfo: user.designerInfo ?? null,
    experiments: reduceExperiments(experimentVariations),
    topStyles: (user.subStyles || []).reduce<ITopStyles>((acc, next, idx) => {
      if (idx === 0) {
        return { ...acc, primaryStyleId: next.id };
      }
      if (idx === 1) {
        return { ...acc, secondaryStyleId: next.id };
      }

      return acc;
    }, {
      primaryStyleId: null,
      secondaryStyleId: null
    })
  };

  window.analytics.identify(
    user.id,
    analyticsData,
    {
      havenly_anonymous_id: Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE),
      integrations: {
        All: true,
        AppBoy: false,
      },
    },
  );

  if (window.dataLayer) {
    window.dataLayer.push({
      user: {
        userId: user.id,
        ...analyticsData
      },
      event: 'identify'
    });
  }
}

export function analyticsCouponCodeCheckout(promoCoupon: string) {
  if (window && promoCoupon !== '') {
    window?.dataLayer?.push({
      couponCode: promoCoupon,
      event: 'identify'
    });
  }
}
