/* istanbul ignore file */

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
// http://unixpapa.com/js/key.html

/* eslint-disable quote-props */
export default {
  ')': '0',
  '!': '1',
  '@': '2',
  '#': '3',
  '$': '4',
  '%': '5',
  '^': '6',
  '&': '7',
  '*': '8',
  '(': '9',
  '<': ',',
  '>': '.',
  '?': '/',
  ':': ';',
  '"': '\'',
  '{': '[',
  '}': ']',
  '|': '\\',
  '_': '-',
  '+': '=',
};
/* eslint-enable quote-props */
