import Cookie from 'js-cookie';

export const PROMOTIONAL_CODE_COOKIE = 'promotionalCode';

const options = {
  expires: 30,
  httpOnly: false,
  sameSite: 'strict' as 'strict'
};

const promotionalCodeCookie = {
  set(code: string) {
    Cookie.set(
      PROMOTIONAL_CODE_COOKIE,
      code,
      options
    );
  }
};

export default promotionalCodeCookie;
