import React from 'react';

export interface IMessagingContext {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  roomId?: string | number | null;
  setRoomId: (roomId?: string | number | null) => void;
}

/* istanbul ignore next */
const MessagingContext = React.createContext<IMessagingContext>({
  isOpen: false,
  setIsOpen: () => {},
  roomId: null,
  setRoomId: () => {},
});

export default MessagingContext;
