'use client';

import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import env from '@lib/envs/env';
import isBrowser from '@utils/browser/isBrowser';

let didInit = false;

export default function DatadogBrowserLogsInit() {
  useEffect(() => {
    if (!didInit && isBrowser()) {
      didInit = true;
      datadogLogs.init({
        clientToken: env.DD_CLIENT_TOKEN,
        site: 'datadoghq.com', // https://docs.datadoghq.com/getting_started/site/
        service: 'customer-ui-client',
        env: env.ENV,
        // we're going to manually send logs for events we're interested in
        // so we don't blow up our index quota
        forwardErrorsToLogs: false,
        sessionSampleRate: ['prod', 'dev'].includes(env.ENV || '') ? 100 : 0,
      });
    }
  }, []);

  // Render nothing - this component is only included so that the init code will run client-side
  return null;
}
