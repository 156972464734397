import { Observable } from '@apollo/client';

export default function promiseToObservable<T>(promise: Promise<T>) {
  return new Observable(
    (observer) => {
      promise.then(
        (value) => {
          if (observer.closed) return;
          observer.next(value);
          observer.complete();
        },
        (err) => observer.error(err)
      );
    }
  );
}

