/* istanbul ignore file */
import logger from '@lib/logger';
import env from '../envs/env';
import isBrowser from '../../utils/browser/isBrowser';

// Add analytics key to global window type
declare global {
  // @ts-ignore
  interface Window { analytics: any; dataLayer?: Array<any> }
}

const initSegment = () => {
  if (!env.ANALYTICS_ENABLED || !isBrowser()) return;

  // Create a queue, but don't obliterate an existing one!
  const analytics = window.analytics || [];
  window.analytics = analytics;

  // same for gtm dataLayer
  const dataLayer = window.dataLayer || [];
  window.dataLayer = dataLayer;

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    // eslint-disable-next-line
    if (window.console) {
      logger('analytics').error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = (method: string) => (...args: any) => {
    args.unshift(method);
    analytics.push(args);
    return analytics;
  };

  // For each of our methods, generate a queueing stub.
  // eslint-disable-next-line
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = (key: any, options: any) => {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode!.insertBefore(script, first);
    // eslint-disable-next-line no-underscore-dangle
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(
    env.HAVENLY_FEATURE_DEVICE_MODE_ANALYTICS_KEY as string || env.ANALYTICS_KEY as string
  );
};
/**
 * Initalizes the Segment library. This is pulled from Segment directly - do not modify.
 */
export default initSegment;
