import { MERCH_GROUP_CATEGORY_URL_PREFIX } from '@components/shared/shop/model/MerchGroupCategory';
import { MERCH_GROUP_URL_PREFIX } from '@components/shared/shop/model/MerchGroup';
import { IMegaMenuColumn } from '../MainHeader/Desktop/MegaMenu/MegaMenu';
/* eslint-disable import/prefer-default-export */

// Shop nav is Prismic-driven
// Below is a default value to be used only if prismic data fails to load
export const defaultShopNav: Array<IMegaMenuColumn> = [
  {
    title: 'Category',
    links: [
      {
        title: 'Furniture',
        url: `${MERCH_GROUP_CATEGORY_URL_PREFIX}/furniture`,
      },
      {
        title: 'Decor & Pillows',
        url: `${MERCH_GROUP_CATEGORY_URL_PREFIX}/decor-pillows`,
      },
      {
        title: 'Rugs',
        url: `${MERCH_GROUP_CATEGORY_URL_PREFIX}/rugs`,
      },
      {
        title: 'Lighting',
        url: `${MERCH_GROUP_CATEGORY_URL_PREFIX}/lighting`,
      },
    ],
  },
  {
    title: 'Featured',
    links: [{
      title: 'Interior Define',
      url: `${MERCH_GROUP_URL_PREFIX}/interior-define`,
    }, {
      title: 'The Inside',
      url: `${MERCH_GROUP_URL_PREFIX}/the-inside-by-havenly`,
    }, {
      title: 'Trending with Havenly Designers',
      url: `${MERCH_GROUP_URL_PREFIX}/tendingwithhavenlydesigners`,
    }, {
      title: 'Havenly Design Awards',
      url: `${MERCH_GROUP_URL_PREFIX}/havenly-design-awards`
    }, {
      title: 'Moody-Luxe is Trending',
      url: `${MERCH_GROUP_URL_PREFIX}/moody-luxe`
    }, {
      title: 'Decor Must Haves',
      url: `${MERCH_GROUP_CATEGORY_URL_PREFIX}/decor-pillows`
    }],
  },
  {
    title: 'Design Awards',
    links: [{
      title: 'Best Sectional for Small Spaces',
      url: `/design-awards/best-sectional-small-spaces`,
    },
    {
      title: 'Best Throw Blanket',
      url: `/design-awards/best-throw-blanket`,
    },
    {
      title: 'Best Curtains',
      url: `/design-awards/best-curtains`,
    },
    {
      title: 'Best Couch',
      url: `/design-awards/best-couch`,
    },
    {
      title: 'Best Bed',
      url: `/design-awards/best-bed`,
    },
    {
      title: 'View All Categories',
      url: `/design-awards`,
    }],
  },
  {
    title: 'Refresh for Under $100',
    text: '',
    image: {
      src: 'https://s3.amazonaws.com/static.havenly.com/content/submenu/homepage-dropdown-SHOP-nonsale.jpg',
      link: `${MERCH_GROUP_URL_PREFIX}/decor-under-100`
    },
  }
];
