import reverseObject from '@lib/reverseObject';

export const RoomTypeSlugsById: { [key: number]: string } = {
  2: 'dining-room',
  1: 'living-room',
  3: 'bedroom',
  4: 'nursery',
  5: 'office',
  6: 'other',
};

export const RoomTypeSlugsByName = reverseObject(RoomTypeSlugsById, true);
