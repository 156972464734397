import React from 'react';

export interface IThemeContext {
  styles: {[key: string]: any};
  colors: {[key: string]: string};
  fonts: {[key: string]: string};
  fontLinks?: string[];
}

const ThemeContext = React.createContext<IThemeContext>({
  styles: {},
  colors: {},
  fonts: {},
  fontLinks: []
});

export default ThemeContext;
