import React, { FC, ComponentType } from 'react';

type Components = ComponentType | [ComponentType, { [key: string]: any }];

interface IProps {
    components: Components[];
}

/**
 * Shamelessly stolen from
 * https://medium.com/@juliuskoronci/avoid-a-long-list-of-react-providers-c45a269d80c1
 */
export const Compose: FC<IProps> = ({ components, children }) => (
  <>
    {components.reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </>
);

export default Compose;
