import * as jsCookie from 'js-cookie';
import { IAuthResponse, Role, Roles } from '@models/shared/i-auth-response';
import { IUser } from '@models/User/i-user';
import { isExpired } from '@utils/jwt';
import env from '../envs/env';
import ME from '../../graphql/User/queries/me.graphql';

// Do not access these directly, always use getCookieName
export const JWT_COOKIE = 'havenly_jwt[jwt]';

// Havenly Apigility Token
const APIGILITY_COOKIE = 'havenly_api_token[token]';

// Cookie is not compliant with modern cookie naming standards, alt name here for E2e tests to pass
const JWT_E2E_COOKIE = 'havenly_jwt-token';

export const getCookieName = () => {
  if (env.E2E) {
    return JWT_E2E_COOKIE;
  }

  return JWT_COOKIE;
};

export const MOCK_AUTH_RESPONSE: IUser = {
  jwt: '12345',
  id: 'fake-id',
  username: 'test-user',
  name: 'test user',
  firstName: 'test',
  lastName: 'user',
  role: 'customer',
  timezone: 'America/Denver',
  created: '0001-01-01T00:00:00Z',
  exp: 157784760,
  designerInfo: {
    id: '54321',
    needsReview: false,
    isHomeStylist: false,
    isStudio3d: false,
    isInPerson: false,
    isOverstockDesigner: false,
    displayName: 'test user',
    timezone: 'America/Denver',
    requiresTermsAcceptance: false
  },
};

export const getJwtCookie = (expiryDate: Date, jwt: string) => {
  const cookieValue = `${getCookieName()}=${jwt}`;

  return `${cookieValue}; path=/; domain=.${env.DOMAIN_NAME}; expires=${expiryDate.toUTCString()}`;
};

export const getDeleteCookie = () => `${getCookieName()}=; path=/; domain=.${env.DOMAIN_NAME}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;

export const redirectUserToLogin = () => {
  const loginUrl = `${env.LOGIN_URL}?redirect=${window.location.href}`;
  window.location.assign(loginUrl);
  return null;
};

function deleteJwtCookie() {
  jsCookie.remove(getCookieName());

  // JS cookie remove is failing on special characters most likely, manually deleting
  document.cookie = getDeleteCookie();
}

async function fetchLoggedInUserData(
  jwt: string
): Promise<IUser | undefined> {
  const response = await fetch(`${env.getApolloUrl()}`, {
    body: JSON.stringify({
      query: ME.loc?.source.body,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    method: 'post',
  });

  if (response.status >= 200 && response.status < 400) {
    const me = await response.json();
    return me?.data?.me;
  }

  return undefined;
}

export const getJwt = (): string | undefined => {
  return jsCookie.get(getCookieName());
};

export const login = async (): Promise<IAuthResponse | null> => {
  const jwt = getJwt();

  if (!jwt || isExpired(jwt)) {
    return redirectUserToLogin();
  }

  const validateResult = await validateJwtCookie();

  if (!validateResult.ok) {
    deleteJwtCookie();
    return redirectUserToLogin();
  }

  const user = await validateResult.json();

  return {
    jwt,
    ...user,
  };
};

export const verifyCurrentUser = async (auth?: IAuthResponse | null): Promise<IUser | null> => {
  let jwt: string | undefined;
  let user: IUser;
  if (!auth) {
    jwt = getJwt();
    if (!jwt || isExpired(jwt)) return null;

    const validateResult = await validateJwtCookie();

    if (!validateResult.ok) {
      deleteJwtCookie();
      return null;
    }

    const validatedUser = await validateResult.json();

    user = {
      ...validatedUser,
      jwt
    };
  } else {
    jwt = auth.jwt;
    user = {
      ...auth,
    };
  }

  const loggedInUserData = await fetchLoggedInUserData(jwt);

  if (loggedInUserData) {
    user = {
      ...user,
      ...loggedInUserData,
    };
  }

  return user;
};

export const logout = () => {
  window.location.assign('/logout');
};

export const refreshToken = async () => {
  const refreshResult = await _refreshToken();

  if (!refreshResult.ok) {
    deleteJwtCookie();
    return;
  }
  const refresh = await refreshResult.json();

  const expiryDate = new Date();
  expiryDate.setSeconds(expiryDate.getSeconds() + refresh.expires_in);
};

export const needsJwtApigilityRefresh = () => {
  const apigilityCookie = jsCookie.get(APIGILITY_COOKIE);
  const jwtCookie = jsCookie.get(getCookieName());

  return apigilityCookie && !jwtCookie;
};

export const getJwtFromApigilityToken = async () => {
  return authenticate();
};

async function authenticate() {
  await fetch('/api/auth/authenticate', { method: 'POST' });
}

async function validateJwtCookie() {
  return fetch('/api/auth/validate', { method: 'POST' });
}

async function _refreshToken() {
  return fetch('/api/auth/refresh', { method: 'POST' });
}

function userHasRole(role: Role, user?: IAuthResponse|null) {
  if (!user) {
    return false;
  }

  return user.role === role;
}

export function userIsAdmin(user?: IAuthResponse|null) {
  return userHasRole(Roles.ADMIN, user);
}

export function userIsDesigner(user?: IAuthResponse|null) {
  return userHasRole(Roles.DESIGNER, user);
}

export function userIsDesignerOrAdmin(user?: IAuthResponse|null) {
  return userIsDesigner(user) || userIsAdmin(user);
}
