import styles from './styles.module.scss';
import themeColors from './colors.module.scss';

const colors = themeColors;

const fonts = {
  primary: 'Graphik, sans-serif',
  secondary: 'Simula, sans-serif',
  tertiary: 'ABC Monument Grotesk, sans-serif'
};

const fontsLinks = [
  'https://static.havenly.com/fonts/simula/Simula-Book.woff2',
  'https://static.havenly.com/fonts/simula/Simula-BookItalic.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-Light-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-LightItalic-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-Regular-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-RegularItalic-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-Medium-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-MediumItalic-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-Semibold-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-SemiboldItalic-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-Bold-Web.woff2',
  'https://static.havenly.com/fonts/graphik/Graphik-BoldItalic-Web.woff2',
];

export default {
  styles, colors, fonts, fontsLinks
};

