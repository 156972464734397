import { IDesignerInfo } from '../User/i-user';

export enum Roles {
  CUSTOMER = 'customer',
  DESIGNER = 'designer',
  ADMIN = 'admin',
}

export type Role = 'customer' | 'designer' | 'admin';

export interface IAuthResponse {
  jwt: string;
  exp: number;
  id: string;
  username?: string;
  email?: string;
  name: string;
  role: Role;
  timezone: string;
  created: string;
  designerInfo?: IDesignerInfo;
  zipCode?: string;
}
