import React from 'react';
import { Stripe } from './getStripe';

export interface IStripeContext {
  stripe: Promise<Stripe | null>;
}

/* istanbul ignore next */
const StripeContext = React.createContext<IStripeContext>({
  stripe: Promise.resolve(null)
});

export default StripeContext;
