/* istanbul ignore file */
import React, { useState } from 'react';
import MessagingContext, { IMessagingContext } from '../../../contexts/User/messaging-context';

export default function MessagingProvider(props: { children: any }) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [roomId, setRoomId] = useState(null);

  return (
    <MessagingContext.Provider
      value={{
        isOpen,
        setIsOpen,
        roomId,
        setRoomId,
      } as IMessagingContext}
    >
      {children}
    </MessagingContext.Provider>
  );
}
