import { Stripe, loadStripe } from '@stripe/stripe-js';
import env from '@lib/envs/env';

export type { Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
export default function getStripe() {
  if (!stripePromise) {
    stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY!);
  }
  return stripePromise;
}
