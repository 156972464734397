import React from 'react';

export interface IDiscoverFeedContext {
  boards: any[];
  posts: any[];
  searchRoomType: number[];
  searchStyle: number[];
  loadingResults: boolean;
  setSearchRoomType: (searchRoomType: number[]) => void;
  setSearchStyle: (searchStyle: number[]) => void;
  fetchMore: () => Promise<[ any, number ]>;
  refresh: () => Promise<[ any, number ]>;
}

/* istanbul ignore next */
const DiscoverFeedContext = React.createContext<IDiscoverFeedContext>({
  boards: [],
  posts: [],
  searchRoomType: [],
  searchStyle: [],
  loadingResults: false,
  setSearchRoomType: () => {},
  setSearchStyle: () => {},
  fetchMore: async () => { return [undefined, 0]; },
  refresh: async () => { return [undefined, 0]; },
});

export default DiscoverFeedContext;
