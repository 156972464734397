export default function parseUrl(url: string | undefined | null) {
  // Prismic forces a prefix of 'https://' on url link fields,
  // so split that out.
  //
  // Then, take everything after the first '/', which shall be the
  // slug used for env based routing.
  //
  // 'https://<whatever>/something' => '/something'
  // 'https://<whatever>/something/other' => '/something/other'
  //
  // This still expects a valid url input from Prismic.

  return url ? `/${url.split(`https://`)[1].split(/\/(.+)/)[1]}` : '';
}
