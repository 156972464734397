import React from 'react';
import StripeContext from './stripe-context';
import getStripe from './getStripe';

interface IStripeProviderProps {
  children: any;
}

const stripe = getStripe();

export default function StripeProvider(props: IStripeProviderProps) {
  return (
    <StripeContext.Provider
      value={{ stripe }}
    >
      {props.children}
    </StripeContext.Provider>
  );
}
